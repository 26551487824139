import React from "react"
import styled from "styled-components"

const Thead = styled.thead`
  background-color: #e5007d;
  color: white;
`

const Small = styled.p`
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
`

function Prices({ pricesData }) {
  const pricesTable = pricesData.map(element => {
    const { id, usluga: title, czas: time, cena: price } = element

    return (
      <tr key={id}>
        <td>{title}</td>
        <td>{time}</td>
        <td>{price}</td>
      </tr>
    )
  })

  return (
    <div className="container mt-6 mb-5">
      <div className="row">
        <h1 className="page-header ml-auto mr-auto">Cennik</h1>
      </div>
      <div className="row mt-5">
        <table className="table table-bordered">
          <Thead>
            <tr>
              <th>Usługa</th>
              <th>Czas trwania</th>
              <th>Cena</th>
            </tr>
          </Thead>
          <tbody>{pricesTable}</tbody>
        </table>
      </div>
      <div className="row">
        <Small className="ml-3 mr-3">
          *w skład terapii indywidualnej wchodzi diagnostyka oraz terapia
          dostosowana indywidualnie do każdego pacjentka m. in. terapia
          manualna, masaż tkanek głębokich, punkty spustowe, kinesiotaping,
          pinoterapia, suche igłowanie , masaż wibracyjny, bańki bezogniowe,
          maniupalcje oraz mobilizacje oraz zalecenia do domu
        </Small>
      </div>
    </div>
  )
}

export default Prices
